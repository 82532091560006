import React from 'react'
import logo from '../img/logo_award2024.png'
import '../styles/award.sass'

export default class Navbar extends React.Component {

  render() {
    return (
      <div className="award">
        <img className="award-logo" src={logo} alt="silver agancy of the year 2024" />
        <div className="award-achievements">
          <p className="award-achievement">pristagare årets byrå 2024</p>
          <p className="award-achievement">awarded agency of the year 2024</p>
        </div>
      </div>
    )
  }
}