import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import '../styles/navbar.sass'
import Arrow from '../components/Arrow'
import logo_b from '../img/logo_ukraine.png'
import logo_w from '../img/logo_ukraine.png'

export default class Navbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = { isTransparent: typeof props.transparent !== 'undefined' || props.transparent !== '' ? props.transparent : true, isOpen: false, heading: '', currentLocation: '' };
  }

  componentDidMount() {
    this.setState({ currentLocation: window.location.pathname.replace(/\//g,'') })
    this.setState({ heading: this.props.title })
    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.checkNavbarStatus()
      })
    }, { passive: true });

    this.checkNavbarStatus()

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkNavbarStatus)
  }

  checkNavbarStatus = () => {
    const navbarToggleDistance = this.props.index ? window.innerHeight - 150 : 100;
    let scrollY = window.scrollY;

    if (typeof this.props.transparent == 'undefined') {
      if (scrollY < (navbarToggleDistance)) {
        this.setState({ isTransparent: true })
      } else if (scrollY > 0) {
        this.setState({ isTransparent: false })
      } else {
        this.setState({ isTransparent: true })
      }
    }

    if (!this.props.title) {
      if (scrollY > navbarToggleDistance) {
        this.setState({ heading: 'PROUD WORKING WITH'})
      } else {
        this.setState({ heading: ''})
      }
    }
  }

  toggleMenu = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen })
  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPage(
              filter: { title : { nin: ["Agency of the year", "Årets Byrå", "Demo","Cookies","1994"] }, slug: { ne: "preview" },polylang_current_lang: {eq:"en_GB"}, wordpress_parent: { eq: 0 }, acf: { preview: { ne: true } } }
              sort: { fields: menu_order }
              limit: 5) {
              edges {
                node {
                  title
                  slug
                }
              }
            }
          }
        `}
        render={data => (
          <nav className={'navbar' + (this.state.isTransparent ? ' is-transparent' : '') + (this.state.isOpen ? ' is-open' : '')}>
            <div className="navbar-brand">
              <a href="/" className="navbar-logo">
                <img className="logo" src={this.state.isOpen || !this.state.isTransparent ? logo_b : logo_w } alt="adeprimo"/>
              </a>
            </div>
            <div className="navbar-project">
              {this.state.heading.replace("&#038;", "&")}
            </div>
            <div className="navbar-end">
              <a
                className="navbar-menu"
                href="#"
                onClick={this.toggleMenu}
              >
                <span className="navbar-menu-opener"></span>
              </a>
              <div className={"navbar-menu-items " + (this.state.isOpen ? 'is-open' : '')}>
                <ul className={"navbar-menu-itemWrapper"}>
                  {data.allWordpressPage.edges.map(edge => (
                    <li className={"navbar-menu-item" + (edge.node.slug == this.state.currentLocation ? ' is-current' : '')} key={edge.node.slug}>
                      <a
                        className="navbar-item"
                        href={(edge.node.slug == this.state.currentLocation ? '#' : '/' + edge.node.slug)}
                        onClick={(edge.node.slug == this.state.currentLocation ? this.toggleMenu : null)}
                        key={edge.node.slug}
                      >
                        {edge.node.slug != this.state.currentLocation &&
                          <Arrow orientation="right is-transparent" />
                        }
                        {edge.node.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        )}
      />
    )
  }
};
