import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Comment from './comment'
import '../styles/chat.sass'
import '../styles/user.sass'

export default class Chat extends React.Component {
  componentDidMount() {
    var list = document.getElementById('list');
    if (typeof list !== 'undefined') {
      list.scrollTop = list.scrollHeight + 1000;
    }
  }

  render() {
    return (
      <div className="chat">
        <p>Powered by Slack. Normally responds in 20ms</p>
        <Comment users={this.props.users} />
      </div>
    )
  }
}