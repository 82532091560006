
export default (prop, start, end, duration, unit = null, overrideStart = null, overrideEnd = null) => {
    const plxConfig = {
      start: overrideStart ? overrideStart : 'self',
      duration: duration,
      properties: [
        {
          startValue: start,
          endValue: end,
          property: prop,
        },
      ],
    }

    if (overrideEnd != null) {
      plxConfig['end'] = overrideEnd;
    }

    if (unit != null) {
      plxConfig.properties[0]['unit'] = unit;
    }

    return plxConfig;
}