import React, { Component } from 'react'
import CommentList from './comment-list'
import Pusher from 'pusher-js'
// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;


    /**
    * initialize pusher with your credentials.
    * Get 'key' from pusher dashboard
    */
    // const pusher = new Pusher('6155d64ba53b85140a71', {
    //   cluster: 'eu',
    //   encrypted: true
    // })

    const token = process.env.SLACK_TOKEN;
    const channel = 'CF6H2P01Y';
    const pusherChannelName = 'adeprimochat';
    const pusherListening = 'new-message';

    // subscribe your pusher instance to the pusherChannel 'pusherChannel
    //const pusherChannel = pusher.subscribe(pusherChannelName)

    /* global fetch */
    export default class Comment extends Component {
      constructor (props) {
        super(props)
        this.state = {
          comments: [],
          author: 'Guest',
          subtype: '',
          message: ''
        }
      }
      

    /**
    * This will load components from the server on app startup,
    * and also subscribe our app to listen for updates
    */
      async componentDidMount () {
        // const comments = await fetch('http://new.adeprimo.com:8080/comments').then(res => res.json())
        const comments =[];
        this.setState({comments: [...comments, ...this.state.comments]})
        // this.receiveUpdateFromPusher()
      }

      componentWillUnmount () {
        // pusher.unsubscribe(pusherChannelName)
        // pusherChannel.unbind()
      }

      /**
      * add new comments to the top of the list
      * once there's an update
      */
      receiveUpdateFromPusher () {
        // pusherChannel.bind(pusherListening, comment => {
        //   this.setState({
        //     comments: [comment, ...this.state.comments]
        //   })
        // })
        console.log('app subscription to event successful')
      }

      handleChange (type, event) {
        if (type === 'message') {
          this.setState({message: event.target.value})
        }
      }

      /**
      * post comment to the server
      */
      async postComment (text) {
        console.log({token, channel, text})
        //await fetch('http://localhost:8080/comment', {
        await fetch('https://hooks.slack.com/services/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', {
          // body: JSON.stringify({token, channel, text}),
          body: JSON.stringify({text}),
          method: 'POST',
          headers: {
            'user-agent': 'Mozilla/4.0 ',
          }
        })
      }

      handleSubmit (event) {
        event.preventDefault()
        this.postComment(this.state.message)
        this.setState({message: ''})
      }

      render () {
        return (
          <div>
            <form id="chatForm" onSubmit={this.handleSubmit.bind(this)}>
                <input type='text' className="chat-input" placeholder="Ask us anything, hit enter to submit." value={this.state.message} onChange={this.handleChange.bind(this, 'message')} />
            </form>
            <CommentList comments={this.state.comments} users={this.props.users} />
          </div>
        )
      }
    }