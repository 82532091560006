import React from 'react'
import SmoothScroll from 'smoothscroll-for-websites'
import Helmet from 'react-helmet'
import Navbar from './Navbar'
import layoutStyles from '../styles/layout.sass'

export default class Layout extends React.Component {
  componentDidMount() {
    // SmoothScroll({ stepSize: 90 })
  }
  render() {
    const { title, transparent, index, children, metatitle, metadesc, metaimage } = this.props

    return (
      <>
        <Helmet title={"adeprimo®"} />
        <meta name="format-detection" content="telephone=no"/>
        <meta charSet="utf-8" />
        {metatitle && <meta name="title" content={metatitle + " - Adeprimo.com"}/>}
        {metadesc && <meta name="description" content={metadesc}/>}
        {metaimage && <meta name="image" content={metaimage}/>}
        {metaimage && <meta name="og:image" content={metaimage}/>}
        <Navbar title={title} transparent={transparent} index={index} />
        <div style={{backgroundColor:'#fff'}}>{children}</div>
      </>
    )
  }
}