import React from 'react'
import '../styles/chat.sass'
import '../styles/user.sass'

export default class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dateTimeFormat: null };
  }
  componentDidMount() {
    const locale = window.navigator.userLanguage || window.navigator.language;
    
    this.setState({ dateTimeFormat: new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit'
    })});
  }
  render() {
    const { comments, users } = this.props
    return (
      <ul id="list" className="chat-messages">
        {comments.map((comment, i) => (
        <li key={i}>
            <div className="user">
              <div className="user-image" style={{backgroundImage: users[comment.author] ? users[comment.author].image : ''}}>
                {/* {users[comment.author] ? '' : ':)'} */}
                {comment.user ? 'a' : ':)'}
              </div>
              <div className="user-details">
                <div className="user-details-info">
                  <div className="user-details-info-name">
                    {comment.user ? 'Adeprimo' : 'Guest'}
                  </div>
                  <div className="user-details-info-time">
                    {this.state.dateTimeFormat.format(comment.ts)}
                  </div>
                </div>
                <div className="user-details-message">
                  {comment.text}
                </div>
              </div>
            </div>
        </li>
        ))}
      </ul>
  )}
}