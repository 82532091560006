import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Chat from '../components/Chat'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import Award from '../components/Award'
import '../styles/footer.sass'
import '../styles/editor.sass'
import logo_w from '../img/logo_ukraine.png'

const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(sort: { fields: wordpress_id }, limit: 5) {
          edges {
            node {
              title
              slug
            }
          }
        }
        allWordpressAcfOptions{
          edges{
            node{
              options{
                footer_nav_links{
                  nav_link
                  name
                }
                footer_social_links{
                  social_link{
                    title
                    url
                    target
                  }
                }
                footer_logos{
                  link
                  logo{
                    localFile{
                      childImageSharp{
                        original{
                          src
                        }
                      }
                    }
                  }
                }
                office{
                  office_title
                  address_line_1
                  address_line_2
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <div className="footer">
          <div className="footer-columns">
            <div className="footer-columns-column">
              <div className="footer-columns-column-header">
                <img className="footer-logo" src={logo_w} />
              </div>
            </div>
            <div className="footer-columns-column">
              <div className="footer-columns-column-header">
                <h2 className="footer-phone">063-555 800</h2><h4>– Old school, we know - but we are pretty old in the game too!</h4>
              </div>
            </div>
          </div>
          <div className="footer-columns">
            <div className="footer-columns-column is-wrapping">
              <div className="footer-links">
                {data.allWordpressAcfOptions.edges[0].node.options.footer_nav_links.map((nav_link, index) => (
                  <div className="footer-links-item" key={index}>
                    <a className="footer-links-link" href={nav_link.nav_link}>{nav_link.name}</a>
                  </div>
                ))}
                {data.allWordpressAcfOptions.edges[0].node.options.footer_social_links.map(({ social_link: link }, index) => (
                  <div className="footer-links-item" key={index}>
                    <a className="footer-links-link" href={link.url}>{link.title}</a>
                  </div>
                ))}
              </div>
              <div className="footer-logos">
                <div className="footer-logos-text">Also a part of the Adeprimo family</div>
                {data.allWordpressAcfOptions.edges[0].node.options.footer_logos.map(({ logo, link }, index) => (
                  <a href={link} key={index}>
                    <img className="footer-logos-logo" src={logo.localFile ? logo.localFile.childImageSharp.original.src : ''} />
                  </a>
                ))}
              </div>
              <div className="footer-links" style={{ paddingBottom: 0 }}>
                <div className="footer-links-item">
                  <a className="footer-links-link" href="/cookies">Cookies</a>
                </div>
              </div>
            </div>
            <div className="footer-columns-column">
              <div className="footer-offices-wrapper">
                <ul className="footer-offices">
                  {data.allWordpressAcfOptions.edges[0].node.options.office.map(({ office_title, address_line_1, address_line_2 }, index) => (
                    <li key={index} className="footer-offices-office">
                      <h3 className="footer-offices-office-header">{office_title}</h3>
                      <div>{address_line_1}</div>
                      <div>{address_line_2}</div>
                    </li>
                  ))}
                  <li className="footer-offices-office">
                    {/* I am supposed to be here to fix layout */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-columns">
            <div className="footer-columns-column">
              <div className="footer-award">
                <Award />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Footer
